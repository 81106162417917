export function destroyChartOnCache(divId) {
  document.addEventListener("turbo:before-cache", function () {
    var chartElement = document.querySelector(`#${divId}`);
    if (chartElement && chartElement.apexChart) {
      chartElement.apexChart.destroy();
      chartElement.apexChart = null; // 🔹 Limpia la referencia del gráfico antes de almacenar en caché
    }
  });
}

export function destroyChartBeforeRender(divId) {
  document.addEventListener("turbo:before-render", function () {
    var chartElement = document.querySelector(`#${divId}`);
    if (chartElement && chartElement.apexChart) {
      chartElement.apexChart.destroy();
      chartElement.apexChart = null; // 🔹 Elimina la referencia al gráfico antes de renderizar
    }
  });
}

export function hideLoadingSpinner(divId) {
  var loadingSpinner = document.getElementById(`loading-${divId}`);
  if (loadingSpinner) {
    loadingSpinner.style.display = "none"; // 🔹 Oculta el spinner cuando los datos estén listos
  }
}